import update from 'immutability-helper';

export const productos = (state = {
    "options"  : [
        {
            name : 'CUBIERTAS',
            image: './assets/cubiertas.jpg',
        },
        {
            name : 'BARRAS',
            image: './assets/barra.jpg',
        },
        {
            name : 'SPLÄSH',
            image: './assets/splash.jpg',
        },
    ],
    "selected" : false
}, action) => {
    switch (action.type) {
		case "MERGE-PRODUCTOS-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};