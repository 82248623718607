import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import { toggleImage } from './Actions';

const Magnifier = ({ src, toggleImage }) => {
    return (
        <a className="img-magnifier" onClick={() => toggleImage(src) }>
            <i class="fa fa-search" aria-hidden="true"></i>
        </a>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        toggleImage,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Magnifier);
