import update from 'immutability-helper';

export const medidas = (state = {
    "quantity"  : 1,
    "width" : 64.5,
    "length" : "",
}, action) => {
    switch (action.type) {
		case "MERGE-MEDIDAS-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};