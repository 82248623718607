import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Actions =>
import { handleUpdate, handleSubmit } from "./Actions";

// Components =>

const Resumen = ({ global: { contactPhone }, productos, perfiles, acabados, texturas, medidas, resumen, handleUpdate, handleSubmit}) => {
    useEffect(() => {

    },[]);

    var stepClass = (texturas.selected) ? "step" : "step hidden";

    var nameError = (resumen.error && resumen.error.field === 'name') ? 'error' : '';
    var emailError = (resumen.error && resumen.error.field === 'email') ? 'error' : '';
    var phoneError = (resumen.error && resumen.error.field === 'phone') ? 'error' : '';
    var cityError = (resumen.error && resumen.error.field === 'city') ? 'error' : '';
    var messageError = (resumen.error && resumen.error.field === 'message') ? 'error' : '';

    return(
        <div className="productos" id="step-5">
            {productos.options && perfiles.options && acabados.options && texturas.options ?
                <div className={stepClass}>
                    <big>5.- RESUMEN DE LA SOLICITUD DE COTIZACIÓN</big>
                    <section>
                        <legend>
                            Confirma tus datos y en breve un ejecutivo se comunicará contigo para continuar con el proceso de compra debido a que es un producto especializado.
                            <b>* Si requiere otros productos Kober® favor de solicitarlo a <a href="mailto:contacto@kober.com.mx">contacto@kober.com.mx</a></b>
                        </legend>
                        <Grid container className="form-holder">
                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                <div className="summary">
                                    <h2>RESUMEN</h2>
                                    <Grid container>
                                        <Grid item md={4}>
                                            <div className="img-holder" style={{ backgroundImage: 'url("https://kober.com.mx/storage/'+texturas.selected.img+'")'}}></div>
                                        </Grid>
                                        <Grid item md={8}>
                                            <p>{medidas.quantity} Piezas {productos.selected.name} de {medidas.width} cm (ancho) x {medidas.length.replace('l','')} cm, (largo) {acabados.selected.name} modelo: {texturas.selected.name} Tipo de Perfil: {perfiles.selected.name}</p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item lg={2} md={0} sm={0} xs={0}></Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <h3>DATOS PARA CONTACTO</h3>
                                <Grid container spacing={4}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <input className={nameError} type="text" value={resumen.name} onChange={(e) => handleUpdate('name', e) } placeholder="Nombre:" />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <input className={emailError} type="text" value={resumen.email} onChange={(e) => handleUpdate('email', e) } placeholder="E-mail:" />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <input className={phoneError} type="text" value={resumen.phone} onChange={(e) => handleUpdate('phone', e) } placeholder="Teléfono:" />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <select className={cityError} value={resumen.city} onChange={(e) => handleUpdate('city', e) }>
                                            <option value="">Ciudad:</option>
                                            {["Guadalajara", "Monterrey", "León", "CDMX", "Querétaro", "Aguascalientes"].map((city) => {
                                                return <option value={city}>{city}</option>
                                            })}
                                        </select>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <legend>Resto del país, comunícate al siguiente mail: <a href="mailto:contacto@kober.com.mx">contacto@kober.com.mx</a></legend>
                                        <textarea className={messageError} value={resumen.message} onChange={(e) => handleUpdate('message', e) } placeholder="Mensaje:"></textarea>
                                    </Grid>

                                    <Grid item xs={12} align="right">
                                        { !resumen.submitting ?
                                            <button onClick={() => handleSubmit() }>Finalizar Solicitud</button>
                                        :
                                            <CircularProgress size={30} style={{ marginRight: "20px", color: "#7acc45"}} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </section>
                </div>
            : null}
        </div>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleUpdate,
        handleSubmit
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Resumen);