import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';

// Actions =>

// Components =>
import Welcome from "../Welcome/Welcome";
import Productos from "../Productos/Productos";
import Perfiles from "../Perfiles/Perfiles";
import Acabados from "../Acabados/Acabados";
import Medidas from "../Medidas/Medidas";
import Resumen from "../Resumen/Resumen";


const Main = ({ }) => {
    useEffect(() => {

    },[]);

    return(
        <main>
            <Welcome/>
            <Productos/>
            <Perfiles/>
            <Acabados/>
            <Medidas/>
            <Resumen/>
        </main>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Main);