// slick Carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';

import { connect } from 'react-redux';
// Redux =>
import { bindActionCreators } from 'redux';

import {
  toggleImage,
  UUID,
} from '../Global/Actions';
// Actions =>
import {
  getOptions,
  toggleOption,
} from './Actions';

// Components =>
import Magnifier from '../Global/Magnifier';

const Productos = ({
    global: {windowWidth},
    productos : { options, selected },
    toggleOption,
    getOptions,
}) => {
    return(
        <div className="productos">
            <div className="step">
                <big>1.- PRODUCTOS</big>
                <section>
                    <legend>Elige el producto que te gustaría cotizar</legend>
                    <div className="main-products-holder">
                        {options.map((p) => {
                            var css = (p.name === selected.name) ? "main-product-item selected" : "main-product-item";
                            return (
                                <div key={UUID()} className={css} onClick={() => toggleOption(p) }>
                                    <h2>{p.name}</h2>
                                    <div className="img-holder" style={{ backgroundImage: 'url('+p.image+')'}}>
                                        <Magnifier src={p.image}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </div>
        </div>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        toggleOption,
        getOptions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Productos);