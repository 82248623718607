import React from 'react';

import { connect } from 'react-redux';
// Redux =>
import { bindActionCreators } from 'redux';

import { toggleOption as setAcabado } from '../Acabados/Actions';
// Actions =>
import { UUID } from '../Global/Actions';
import { toggleOption as setProduct } from '../Productos/Actions';
import { toggleOption as setTextura } from '../Texturas/Actions';
import { handleUpdate } from './Actions';

// Components =>

const Medidas = ({
    productos,
    perfiles,
    acabados,
    texturas,
    handleUpdate,
    setProduct,
    setAcabado,
    setTextura,
    medidas : {quantity, width, length},
    resumen: {error}
}) => {
    const selectChange = (e, field) => {
        var id = e.target.value.toString();
        var match;
        switch(field){
            case 'producto':
                match = productos.options.filter((opt) => opt.name.toString() === id )[0];
                setProduct(match);
            break;

            case 'acabado':
                match = acabados.options.filter((opt) => opt.id.toString() === id )[0];
                setAcabado(match);
            break;

            case 'textura':
                match = texturas.options.filter((opt) => opt.id.toString() === id )[0];
                setTextura(match);
            break;
        }

    }

    var stepClass = (texturas.selected) ? "step" : "step hidden";

    var widthError = (error && error.field === 'width') ? 'error' : '';
    var lengthError = (error && error.field === 'length') ? 'error' : '';

    return(
        <div className="productos" id="step-4">
            {perfiles.options && acabados.options && texturas.options ?
                <div className={stepClass}>
                    <big>4.- MEDIDAS Y UNIDADES</big>
                    <section>
                        <legend>Elige las medidas y cantidad de piezas que necesitas</legend>
                        <div className="fields">
                            <fieldset>
                                <select value={productos.selected.name} onChange={(e) => selectChange(e, "producto") }>
                                    {productos.options.map((opt) => {
                                        return <option key={UUID()} value={opt.name}>{opt.name}</option>
                                    })}
                                </select>
                            </fieldset>

                            <fieldset>
                                <label>PIEZAS:</label>
                            </fieldset>

                            <fieldset>
                                <input value={quantity} onChange={(e) => handleUpdate("quantity", e) } type="number" min={1} maxLength={3} max={100} placeholder="No. Piezas"/>
                            </fieldset>

                            <fieldset>
                                <label>ANCHO(cm) : <strong>Máximo 64.5 cm</strong></label>
                            </fieldset>

                            <fieldset className={widthError}>
                                <input value={width} onChange={(e) => handleUpdate("width", e) } type="number" step={0.5} min={1} placeholder="Centímetros"/>
                            </fieldset>

                            <fieldset>
                                <label>LARGO(cm) : <strong>Máximo 360 cm</strong></label>
                            </fieldset>
                            <fieldset className={lengthError}>
                                <select value={""+length+""} onChange={(e) => handleUpdate("length", e) }>
                                    <option value="">Seleccionar:</option>
                                    {['l360', 'l300', 'l240'].map((opt) => {
                                        return <option key={UUID()} value={opt}>{opt.replace('l','')} cm</option>
                                    })}
                                </select>
                            </fieldset>

                            <fieldset>
                                <select value={acabados.selected.id} onChange={(e) => selectChange(e, "acabado") }>
                                    {acabados.options.map((opt) => {
                                        return <option key={UUID()} value={opt.id}>{opt.name}</option>
                                    })}
                                </select>
                            </fieldset>

                            <fieldset>
                                <select value={texturas.selected.id} onChange={(e) => selectChange(e, "textura") }>
                                    {texturas.options.map((opt) => {
                                        return <option key={UUID()} value={opt.id}>{opt.name}</option>
                                    })}
                                </select>
                            </fieldset>
                        </div>
                        <small>Si requiere asesoría en medidas especiales favor de <a href="https://kober.com.mx/contacto" taerget="_blank">contactarnos</a> </small>
                    </section>
                </div>
            : null }
        </div>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleUpdate,
        setProduct,
        setAcabado,
        setTextura
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Medidas);