import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';

// Actions =>

// Components =>

const Welcome = ({ }) => {
    useEffect(() => {

    },[]);

    return(
        <div className="welcome">
            <h1>PONLE KOBER A TU COCINA.</h1>
            <p>Queremos que tu cocina tenga la cubierta más encantadora de todas y que ese espacio sea el más inspirador de la casa. <b>Déjanos tus datos y con gusto te asesoramos y cotizamos.</b></p>
        </div>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Welcome);