import React, { useEffect } from 'react';

import { connect } from 'react-redux';
// Redux =>
import { bindActionCreators } from 'redux';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';

// Components =>
import Texturas from '../Texturas/Texturas';
import Magnifier from '../Global/Magnifier';

// Actions =>
import {
  getOptions,
  toggleOption,
} from './Actions';

const Acabados = ({ acabados : { options, selected}, perfiles, toggleOption, getOptions }) => {
    useEffect(() => {
        getOptions();
    },[]);

    var stepClass = (perfiles.selected) ? "step" : "step hidden";

    return(
        <div className="productos acabados-holder" id="step-3">
            <div className={stepClass}>
                <big>3.- ACABADOS</big>
                <section>
                    <legend>Elige la apariencia que deseas dar a tu producto</legend>
                    {!options ?
                        <CircularProgress size={20} />
                    :
                        <div className="options">
                            {options.map((opt) => {
                                var css = (opt.id === selected.id) ? "acabado selected" : "acabado";
                                return(
                                    <button key={"acabado-"+opt.id} className={css} type="button" onClick={() => toggleOption(opt) }>
                                        <div className="content">
                                            <a className='link-title' target="_blank" href={opt.link}>{opt.name}</a>
                                            <div className="img-holder" style={{ backgroundImage: 'url("https://kober.com.mx/storage/'+opt.img+'")'}}>
                                                <Magnifier src={"https://kober.com.mx/storage/"+opt.img}/>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })}

                            <Texturas/>
                        </div>
                    }
                </section>
            </div>
        </div>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        toggleOption,
        getOptions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Acabados);