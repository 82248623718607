// API
import { API, windowScroll } from "../Global/Actions";


// Actions
import {getOptions as getTexturas} from "../Texturas/Actions";


export function getOptions(){
    return (dispatch) => {
        API('get-tiposacabados').then((res) => {
            dispatch({
                type 	: "MERGE-ACABADOS-STATES",
                payload	: {
                    "options" : res
                }
            });
        });
    }
}


export function toggleOption(opt){
    return (dispatch) => {
        dispatch({
            type 	: "MERGE-ACABADOS-STATES",
            payload	: {
                "selected" : opt
            }
        });
        dispatch(getTexturas(opt.id)).then(() => {
            windowScroll((document.getElementById('step-3-1').offsetTop - 40), 0);
        });
    }
}