import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';

const Header = ({ global: { contactPhone } }) => {
    return(
        <header>
            <Grid container className="top">
                <Grid item xs={12}>
                    <a target="_blank" rel="noreferrer" href={'tel:' + contactPhone}><i className="fas fa-phone"></i> { contactPhone }</a>
                    <a target="_blank" rel="noreferrer" href="mailto:contacto@kober.com.mx"><i className="fas fa-envelope"></i> contacto@kober.com.mx</a>
                </Grid>
            </Grid>
            <Grid container className="bottom">
                <Grid item lg={2} md={1} sm={12} xs={12}>
                    <a className="logo-holder" target="_blank" rel="noreferrer" href="https://kober.com.mx">
                        <img src="https://kober.com.mx/images/logo.svg" alt="Kober"/>
                    </a>
                </Grid>
                <Grid item lg={10} md={11} sm={12} xs={12}>
                    <nav>
                        <a target="_blank" rel="noreferrer" href="https://kober.com.mx/">Inicio</a>
                        <a target="_blank" rel="noreferrer" href="https://kober.com.mx/productos">Productos</a>
                        <a target="_blank" rel="noreferrer" href="https://kober.com.mx/aliados">Haz tu cocina</a>
                        <a target="_blank" rel="noreferrer" href="https://visualizador.kober.com.mx/">Visualizador de cocina</a>
                        <a target="_blank" rel="noreferrer" href="https://kober.com.mx/proyectos">Proyectos</a>
                        <a target="_blank" rel="noreferrer" href="https://kober.com.mx/novedades">Novedades</a>
                        <a target="_blank" rel="noreferrer" href="https://kober.com.mx/blog">Blog</a>
                        <a target="_blank" rel="noreferrer" href="https://kober.com.mx/contacto">Contacto</a>
                        <a target="_blank" rel="noreferrer" href="/">VENTA EN LÍNEA</a>
                    </nav>
                </Grid>
            </Grid>
        </header>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Header);