import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { toggleOption } from "./Actions";

// Components =>
import Magnifier from '../Global/Magnifier';

const Texturas = ({ texturas : { options, selected}, toggleOption }) => {
    return(
        <React.Fragment>
            {!options ?
                null
            :
                <React.Fragment>
                    <h2>*El color puede variar en físico.</h2>
                    <div className="acabados" id="step-3-1">
                        {options.map((opt) =>{
                            var css = (opt.id === selected.id) ? "acabado-holder selected" : "acabado-holder";
                            return(
                                <div className={css} key={"textura-"+opt.id} onClick={() => toggleOption(opt) }>
                                    <div className="img-holder" style={{ backgroundImage: 'url("https://kober.com.mx/storage/'+opt.img+'")'}}>
                                        <Magnifier src={"https://kober.com.mx/storage/"+opt.img} />
                                    </div>
                                    <span>{opt.name}</span>
                                </div>
                            )
                        })}
                    </div>
                    <h2>*El color puede variar en físico.</h2>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        toggleOption
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Texturas);