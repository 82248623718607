import update from 'immutability-helper';

export const acabados = (state = {
    "options"  : false,
    "selected" : false
}, action) => {
    switch (action.type) {
		case "MERGE-ACABADOS-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};