// Handle field update states:
export function handleUpdate(field, e){
    return (dispatch, getState) => {
        var value = e.target.value;

        // Width and length must be 64.5cm or less
        if(["width"].includes(field)){
            if(parseFloat(value) > 64.5){
                value = 64.5;
            }
        }

        // Width and length must be 300cm or less
        if(["length"].includes(field)){
            if(parseFloat(value) > 300){
                value = 300;
            }
        }

        if(field === "quantity"){
            value = value.replace(/^\[0-9]+$/g, '');
            value = value.replace('-', '');
            if(parseInt(value) > 100)
            value = 100;
        }

        dispatch({
            type 	: "MERGE-MEDIDAS-STATES",
            payload	: {
                [field] : value
            }
        });
    }
}