import {combineReducers} from 'redux';

import {global} from './Global/Reducer';
import {productos} from './Productos/Reducer';
import {perfiles} from './Perfiles/Reducer';
import {acabados} from './Acabados/Reducer';
import {texturas} from './Texturas/Reducer';
import {medidas} from './Medidas/Reducer';
import {resumen} from './Resumen/Reducer';

// Combine Them All! =>
const all = combineReducers({
    global,
    productos,
    perfiles,
    acabados,
    texturas,
    medidas,
    resumen
});

export default all;