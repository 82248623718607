import update from 'immutability-helper';

export const resumen = (state = {
    "name"  : "",
    "email" : "",
    "phone" : "",
    "city" : "",
    "comments" : "",
    "submitting" : false,
    "error" : false,
}, action) => {
    switch (action.type) {
		case "MERGE-RESUMEN-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};