import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Redux =>
import { createStore, applyMiddleware } from 'redux';

// Thunk =>
import thunk from 'redux-thunk';

// Reducers =>
import CombinedReducers from './combine-reducers';

// Provider =>
import {Provider} from 'react-redux';


import {windowSize} from "./Global/Actions";

// Redux Store is initialized here =>
const store = createStore(CombinedReducers, applyMiddleware(thunk));


store.dispatch(windowSize()); // Dispatch to get initial sizes
window.addEventListener('resize', () => {
    store.dispatch(windowSize());  // Stores widnow size in state
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();