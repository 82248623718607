import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';


// Actions =>
import { toggleImage } from './Actions';

// Components =>

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Image = ({ global: {image} , toggleImage }) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        var open = (image === false) ? false : true;
        setOpen(open);
    },[image]);

    return(
        <Dialog
            open={open}
            //TransitionComponent={Transition}
            keepMounted
            onClose={() => toggleImage() }
            maxWidth={"md"}
            className="modal-image"
        >
            <DialogContent>
                <button type="button" onClick={() => toggleImage() }>
                    <i className="far fa-times-circle"></i>
                </button>
                <img src={image}/>
            </DialogContent>
        </Dialog>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        toggleImage
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Image);