import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { toggleOption, getOptions } from "./Actions";

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';

// slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Components =>
import Magnifier from '../Global/Magnifier';

const Perfiles = ({ global: {windowWidth}, productos, perfiles : { options, selected }, toggleOption, getOptions }) => {
    useEffect(() => {
        getOptions();
    },[]);



    const PrevArrow = (props) =>{
        const { onClick } = props;
        return (
            <div className="slick-arrow" onClick={onClick}>
                <i className="fas fa-caret-left"></i>
            </div>
        )
    }


    const NextArrow = (props) =>{
        const { onClick } = props;
        return (
            <div className="slick-arrow r" onClick={onClick}>
                <i className="fas fa-caret-right"></i>
            </div>
        )
    }

    const ww = windowWidth;
    var slidesToShow = 4;
    var slidesToScroll = 4;


    if(ww < 1340){
        slidesToShow = 3;
        slidesToScroll = 4;
    }


    if(ww < 960){
        slidesToShow = 2;
        slidesToScroll = 2;
    }


    if(ww < 600){
        slidesToShow = 1;
        slidesToScroll = 1;
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>
    };

    var stepClass = (productos.selected) ? "step" : "step hidden";

    return(
        <div className="productos" id="step-2">
            <div className={stepClass}>
                <big>2.- PERFILES</big>
                <section>
                    <legend>Elige entre los diferentes grosores y formas {JSON.stringify(productos.s)}</legend>
                    {!options ?
                        <CircularProgress size={20} />
                    :
                        <Slider {...settings} className="options">
                            {options.map((opt) => {
                                var css = (opt.id === selected.id) ? "selected" : "";

                                // 18 de Nov, Condición agregada para que en Barras-K no muestre perfil "slim":
                                var exclude = false;
                                if(productos.selected.name === 'BARRAS' && opt.name === 'SLIM ®'){
                                    exclude = true;
                                }

                                return(
                                    !exclude ?
                                        <button key={"perfil-"+opt.id} className={css} type="button" onClick={() => toggleOption(opt) }>
                                            <div className="content">
                                                <a className='link-title' target="_blank" href={opt.link}>{opt.name}</a>
                                                <div className="img-holder" style={{ backgroundImage: 'url("https://kober.com.mx/storage/'+opt.img+'")'}}>
                                                    <Magnifier src={"https://kober.com.mx/storage/"+opt.img}/>
                                                </div>
                                            </div>
                                        </button>
                                    : null
                                )
                            })}
                        </Slider>
                    }
                </section>
            </div>
        </div>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        toggleOption,
        getOptions,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Perfiles);