import { API } from "../Global/Actions";


// Handle field update states:
export function handleUpdate(field, e){
    return (dispatch) => {
        var value = e.target.value;
        dispatch({
            type 	: "MERGE-RESUMEN-STATES",
            payload	: {
                [field] : value
            }
        });
    }
}





// Handle Submit
export function handleSubmit(){
    return (dispatch, getState) => {
        var state = getState();

        dispatch({
            type 	: "MERGE-RESUMEN-STATES",
            payload	: {
                submitting : true
            }
        });

        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regexNumber = /^\d\.+$/;
        var error = false;

        if(!/^\d+$/.test(state.medidas.quantity)){
            error = {
                "message" : "Es necesario proporcionar una cantidad númerica de piezas",
                "field"   : "quantity",
            };
        } else if(!state.medidas.width || isNaN(state.medidas.width)){
            error = {
                "message" : "Es necesario indicar el ancho de la pieza, menor a 3 metros.",
                "field"   : "width",
            };
        } else if(!state.medidas.length){
            error = {
                "message" : "Es necesario indicar el largo de la pieza, 360cm, 300cm ó 240cm.",
                "field"   : "length",
            };
        } else if(!state.resumen.name || state.resumen.name.length === 0){
            error = {
                "message" : "Es necesario indicar tu nombre.",
                "field"   : "name",
            };
        } else if(! regexEmail.test(state.resumen.email)){
            error = {
                "message" : "Es necesario indicar una cuenta de correo válida.",
                "field"   : "email",
            };
        } else if(!state.resumen.phone || state.resumen.phone.length === 0){
            error = {
                "message" : "Es necesario indicar tu teléfono.",
                "field"   : "phone",
            };
        } else if(!state.resumen.city || state.resumen.city.length === 0){
            error = {
                "message" : "Es necesario indicar tu ciudad.",
                "field"   : "city",
            };
        }

        if(error !== false){
            alert(error.message);
            dispatch({
                type 	: "MERGE-RESUMEN-STATES",
                payload	: {
                    "error" : error,
                    "submitting" : false
                }
            });
        } else {
            var formData = new FormData();
            formData.append('producto', state.productos.selected.name);
            formData.append('perfil', state.perfiles.selected.name);
            formData.append('acabado', state.acabados.selected.name);
            formData.append('textura', state.texturas.selected.name);
            formData.append('cantidad', state.medidas.quantity);
            formData.append('ancho', state.medidas.width);
            formData.append('largo', state.medidas.length);
            formData.append('nombre', state.resumen.name);
            formData.append('correo', state.resumen.email);
            formData.append('telefono', state.resumen.phone);
            formData.append('ciudad', state.resumen.city);
            formData.append('mensaje', state.resumen.message);


            API('submit', 'POST', formData).then((res) => {
                dispatch({
                    type 	: "MERGE-RESUMEN-STATES",
                    payload	: {
                        submitting : false
                    }
                });
                window.location.href = '/gracias';
            });
        }
    }
}