import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';

// Actions =>

// Components =>

const Welcome = ({ }) => {
    useEffect(() => {

    },[]);

    return(
        <main>
            <div className='welcome'>

            <h1 style={{ textAlign: 'center', fontWeight: 300 }}><span style={{ fontWeight: 900 }}>¡Muchas Gracias</span> por visitarnos en Mundo Kober</h1>
            <p style={{ textAlign: 'center',fontWeight: 300, marginTop: '1%' }}>Seguiremos trabajando para mejorar <span style={{ fontWeight: 900 }}>nuestros contenidos.</span></p>
            <p style={{ textAlign: 'center',fontWeight: 300, marginTop: '7%' }}>Te invitamos a conocer más de  <span style={{ fontWeight: 900 }}>Mundo Kober</span></p>
            
            <div style={{display:'flex', gap: '15px', marginTop: '5%'}}>
                <div style={{width: '50%'}}>
                   
                <button  className='button-thank' >
                    <a style={{width:'100%'}} href='https://kober.com.mx/productos'>Ver productos</a>
                </button>
                </div>
                <div style={{width: '50%'}}>
                <button className='button-thank' >
                    <a style={{width:'100%'}} href='/'>Ir al inicio</a>
                </button>
                </div>
            </div>
            
            </div>
        </main>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Welcome);