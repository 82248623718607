// API
import { API, windowScroll } from "../Global/Actions";



export function getOptions(){
    return (dispatch) => {
        API('get-productos').then((res) => {
            dispatch({
                type 	: "MERGE-PRODUCTOS-STATES",
                payload	: {
                    "options" : res
                }
            });
        });
    }
}



export function toggleOption(opt){
    return (dispatch) => {
        dispatch({
            type 	: "MERGE-PRODUCTOS-STATES",
            payload	: {
                "selected" : opt
            }
        });

        windowScroll((document.getElementById('step-2').offsetTop - 40), 0);
    }
}