import update from 'immutability-helper';

export const global = (state = {
    "token"  : null,
    "thanks" : false,
    "image"  : false, // Holds the value of clicked image to be shown in modal
    "contactPhone"  : '33 23 48 93 29',
}, action) => {
    switch (action.type) {
		case "MERGE-GLOBAL-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};