// API Call Wrapper:
export function API(service, method, body, headers = true){
    return new Promise((resolve) => {
        var obj = {'method' : method}

        // Add headers:
        if(headers){
            /*
            obj['headers'] = {
                'Authorization' : `${token.token_type} ${token.access_token}`
            };
            */
        }

        // Add method, or GET by default:
        if(method === 'POST'){
            obj["body"] = body;
        }

        // Old =>
        var domain = 'https://kober.com.mx/api/ecommerce/';
        var request = new Request(domain+service, obj);
        fetch(request).then(res => res.json()).then(json => {
            resolve(json);
        }).catch((error) => {
            resolve({"api-error" : error });
        });
    });
}


// UUID Generator:
export function UUID() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}


// windowSize
export function windowSize(){
    return (dispatch) => {
        dispatch({
            type 	: "MERGE-GLOBAL-STATES",
            payload	: {
                "windowWidth" : window.innerWidth,
                "windowHeight" : window.innerHeight,
            }
        });
    }
}


// Smooth Scrolling
export function windowScroll(top, left){
    window.scroll({ top: top, left: left, behavior: 'smooth' });
}




export function toggleThanks(){
    return (dispatch) => {
        dispatch({
            type 	: "MERGE-GLOBAL-STATES",
            payload	: {
                "thanks" : true
            }
        });
    }
}


export function toggleImage(img = false){
    return (dispatch) => {
        dispatch({
            type 	: "MERGE-GLOBAL-STATES",
            payload	: {
                "image" : img
            }
        });
    }
}