// API
import { API, windowScroll } from "../Global/Actions";



export function getOptions(id){
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            API('get-acabados/'+id).then((res) => {
                dispatch({
                    type 	: "MERGE-TEXTURAS-STATES",
                    payload	: {
                        "options" : res,
                        "selected": false
                    }
                });

                resolve();
            });
        });
    }
}


export function toggleOption(opt){
    return (dispatch) => {
        dispatch({
            type 	: "MERGE-TEXTURAS-STATES",
            payload	: {
                "selected" : opt
            }
        });

        windowScroll((document.getElementById('step-4').offsetTop - 40), 0);
    }
}