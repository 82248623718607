import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// Actions =>
	import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Components =>
import Header from "./Global/Header";
import Main from "./Global/Main";
import Footer from "./Global/Footer";
import Thanks from "./Global/Thanks";
import Image from "./Global/Image";

import { toggleThanks } from './Global/Actions';

const App = ({ toggleThanks }) => {
	useEffect(() => {
		if(document.URL.search("thanks") !== -1)
		toggleThanks();
	},[]);

	return (
		<Router>
		<div id="kober-ecommerce">
		  <Header />
		  <Routes>
			<Route exact path="/" element={<Main />} />
			<Route path="/gracias" element={<Thanks />} />
		  </Routes>
		  <Footer />
		  <Image />
		</div>
	  </Router>
	  );
}

// mapStateToProps =>
function mapStateToProps(state){
	return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
	return bindActionCreators({
		// Actions
		toggleThanks
	}, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(App);